<template>
  <div class="col l4 m6 s12 p5" style="margin-bottom: 15px">
    <select
      name=""
      id="select-input"
      class="browser-default pointer"
      @change="onCourseChange($event)"
      v-model="affiliationCourse"
    >
      <option value="0" disabled selected>Select Course</option>
      <option :value="data.CourseId" v-for="data in coursedata" :key="data.CourseId">
        {{ data.CourseName }}
      </option>
    </select>
    <div class="fs-15 fw-700" v-if="courseValidation" style="color: red; position: absolute">
      Please Select Course
    </div>
  </div>
  <div class="col l4 m6 s12 p5" style="margin-bottom: 15px">
    <select
      name=""
      id="select-input"
      class="browser-default pointer"
      @change="onSubjectChange($event)"
      v-model="affiliationSubject"
    >
      <option value="0" disabled selected>Select Subject</option>
      <option :value="data.SubjectId" v-for="data in subjectData" :key="data.SubjectId">
        {{ data.SubjectName }}
      </option>
    </select>
    <div class="fs-15 fw-700" v-if="subjectValidation" style="color: red; position: absolute">
      Please Select Subject
    </div>
  </div>
  <div class="col l4 m6 s12 p5" style="margin-bottom: 15px">
    <select
      name=""
      id="select-input"
      class="browser-default pointer"
      @change="onTopicChange($event)"
      v-model="affiliationTopic"
    >
      <option value="0" disabled selected>Select Topic</option>
      <option :value="data.TopicId" v-for="data in topic" :key="data.TopicId">
        {{ data.TopicName }}
      </option>
    </select>
    <div class="fs-15 fw-700" v-if="topicvalidation" style="color: red; position: absolute">
      Please Select Topic
    </div>
  </div>
  <!-- </div>
        <div class="row"> -->
  <div class="col l4 m6 s12 p5">
    <select
      name=""
      id="select-input"
      class="browser-default pointer"
      @change="onChapterChange($event)"
      v-model="affiliationChapter"
    >
      <option value="0" disabled selected>Select Chapter</option>
      <option :value="data.ChapterId" v-for="data in chapter" :key="data.ChapterId">
        {{ data.ChapterName }}
      </option>
    </select>
    <div class="fs-15 fw-700" v-if="chapterValidation" style="color: red">
      Please Select Chapter
    </div>
  </div>
  <div class="col l4 m6 s12 p5" v-if="showBatchDropDown">
    <VueMultiselect
      class="browser-default dropd"
      tag-placeholder="Add this Batch"
      v-model="affiliationBatch"
      placeholder="Select Batch"
      @update:model-value="updateSelectedBatch"
      label="Name"
      track-by="Id"
      :options="batchData"
      :multiple="true"
      :taggable="true"
      :limit="1"
      @tag="addBatch"
    >
      <template v-slot:beforeList>
        <div class="row" style="margin-top: 5px">
          <a class="multiSelectButtons waves-effect waves-light btn" @click="selectBatchNone"
            ><span style="display: flex; justify-content: center">None</span></a
          >&nbsp;
          <a class="multiSelectButtons waves-effect waves-light btn" @click="selectBatchAll"
            ><span>All </span></a
          >
        </div>
      </template>
    </VueMultiselect>
    <div class="fs-15 fw-700" v-if="batchValidation" style="color: red">Please Select Batch</div>
  </div>
  <Modal3 :show="showAddModal" :showHeader="false" :showCloseBtn="false" :width="'52%'">
    <template v-slot:body>
      <div class="concept-modal">
        <div class="flex flex-between publish-head">
          <span class="fs-16 fw-700" style="margin-top: 2px">
            {{ isFileUpload ? "Upload File" : "Upload Video" }}</span
          >
          <div @click="closeAddModal()" style="cursor: pointer">
            <span class="material-icons publish-close pointer" style="color: #ffffff !important"
              >close</span
            >
          </div>
        </div>
        <div class="modal-content">
          <div class="row">
            <div class="flex col s12 flex-column">
              <span class="fs-14 fw-700">Title</span>
              <input
                type="text"
                class="date-column"
                v-model="affiliationTitle"
                placeholder="File Name"
              />
              <div v-if="titleValidation" style="color: red">Please Enter Title</div>
            </div>
            <div class="flex col s12 flex-column" v-if="isFileUpload">
              <label class="fs-14 fw-700 dark-font">Select FileType</label>
              <select
                name=""
                id="select-input"
                class="browser-default pointer"
                v-model="fileTypeId"
              >
                <option value="0" disabled selected>Select FileType</option>
                <option
                  :value="data.FileUploadFileTypeId"
                  v-for="data in fileTypeList"
                  :key="data.FileUploadFileTypeId"
                >
                  {{ data.FileType }}
                </option>
              </select>
              <div v-if="fileTypeValidation" style="color: red">Please Select FileType</div>
            </div>
          </div>
          <div class="row">
            <div class="flex col s12 flex-column" v-if="!isFileUpload">
              <span class="fs-14 fw-700">Upload Videos</span>
              <input
                id="file1"
                type="file"
                accept="video/mp4,video/mov,video/HEVC,video/MOV,video/m4v,video/*"
                @change="onUploadFile($event)"
              />
              <div v-if="fileValidation" style="color: red">
                <span>Please Upload Video</span>
              </div>
            </div>
            <div v-else class="flex col s12 flex-column">
              <span class="fs-14 fw-700">Upload File</span>
              <input
                id="file1"
                type="file"
                accept="application/pdf"
                @change="onUploadFile($event)"
              />
              <div v-if="fileValidation" style="color: red">
                <span>Please Upload File</span>
              </div>
            </div>
          </div>
          <div class="row" style="padding-top: 10px">
            <div class="s12">
              <button class="publish-modal-button" @click="uploadData()">Upload</button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal3>
  <Modal3 :show="showMapExistingModal" :showHeader="false" :showCloseBtn="false" :width="'52%'">
    <template v-slot:body>
      <div class="concept-modal">
        <div class="flex flex-between publish-head">
          <span class="fs-16 fw-700" style="margin-top: 2px"> Map Existing Video</span>
          <div @click="closeExistingModal()" style="cursor: pointer">
            <span class="material-icons publish-close pointer" style="color: #ffffff !important"
              >close</span
            >
          </div>
        </div>
        <div class="modal-content">
          <div class="row">
            <div class="flex col s12 flex-column">
              <span class="fs-14 fw-700">Title</span>
              <input
                type="text"
                class="date-column"
                v-model="affiliationTitle"
                placeholder="File Name"
              />
              <div v-if="titleValidation" style="color: red">Please Enter Title</div>
            </div>
            <div class="flex col s12 flex-column" style="margin-top: 10px">
              <span class="fs-14 fw-700">Enter Vimeo Video Id or Youtube url</span>
              <input type="text" class="date-column" v-model="videoUrl" placeholder="File Name" />
              <div v-if="videoUrlValidation" style="color: red">Please Enter video url.</div>
            </div>
            <div class="flex col s12 flex-column" style="margin-top: 10px">
              <label>
                <input type="checkbox" v-model="isYouTube" />
                <span class="fs-14 fw-700">Is YouTube URL</span>
              </label>
            </div>
          </div>
          <div class="row" style="padding-top: 10px">
            <div class="s12">
              <button class="publish-modal-button" @click="mapExistingVideo()">Map Video</button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal3>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import { useToast } from "vue-toastification";
import MobileAPI from "../Mobileapi";
import Modal3 from "./Modal3.vue";

const toast = useToast();

export default {
  components: { VueMultiselect, Modal3 },
  props: {
    showAddModalFromParent: Boolean,
    isSearchButtonClicked: Boolean,
    showBatchDropDown: Boolean,
    isFileUpload: Boolean,
    isUploadButtonClicked: Boolean,
    isMapExistingClicked: Boolean,
    openMapExistingModal: Boolean,
  },
  data() {
    return {
      showMsg: false,
      showDeleteModal: false,
      showAddModal: false,
      isDateRangeOpen: false,
      showmessage: true,
      uploadfile: false,
      coursedata: [],
      batchData: [],
      subjectData: [],
      chapter: [],
      topic: [],
      topicData: {},
      affiliationCourse: 0,
      affiliationSubject: 0,
      affiliationTopic: 0,
      affiliationChapter: 0,
      affiliationBatch: null,
      affiliationTitle: null,
      courseValidation: false,
      subjectValidation: false,
      topicvalidation: false,
      chapterValidation: false,
      batchValidation: false,
      documentValidation: false,
      courseId: "",
      subjectId: "",
      topicId: "",
      chapterId: "",
      batchId: "",
      classId: null,
      selectedBatches: [],
      getInstituteVideos: [],
      deleteCoachingVideo: [],
      whiteListVideo: [],
      affiliationCourseId: "",
      titleValidation: false,
      fileValidation: false,
      coachingId: null,
      videoId: [],
      heading: this.pageHeading,
      title: this.pageTitle,
      selectedProps: {
        courseId: 0,
        subjectId: 0,
        topicId: 0,
        chapterId: 0,
        batches: [],
        courseChapterId: 0,
      },
      fileTypeList: [],
      fileTypeId: null,
      fileTypeValidation: false,
      showMapExistingModal: false,
      videoUrl: null,
      videoUrlValidation: false,
      isYouTube: false,
    };
  },
  emits: ["propsChanged", "modalClosed", "searchValidations"],

  watch: {
    showAddModalFromParent() {
      this.showAddModal = this.showAddModalFromParent;
    },
    isSearchButtonClicked() {
      this.performValidation(true, false);
    },
    isUploadButtonClicked() {
      this.performValidation(false, false);
    },
    isMapExistingClicked() {
      this.performValidation(false, true);
    },
    openMapExistingModal() {
      this.showMapExistingModal = this.openMapExistingModal;
    },
  },
  beforeMount() {
    document.body.style.backgroundColor = "#F6F8FB";
  },
  activated() {
    this.$store.dispatch("showLoader", true);
    // API.getInstituteTestMeta({}, (response) => {
    //   this.$store.dispatch("showLoader", false);
    //   this.coursedata = response.courses;
    // });
    const obj = {
      onlyCourses: 1,
      courseId: 0,
    };
    MobileAPI.getCourseMetaData(this.$store.getters.user.AffiliationId, obj, (response) => {
      this.$store.dispatch("showLoader", false);
      this.coursedata = response.data[0];
    });
    MobileAPI.getFileTypes((response) => {
      this.$store.dispatch("showLoader", false);

      this.fileTypeList = response.data;
    });
  },
  methods: {
    performValidation(isSearch, isMapExistingVideo) {
      const childObj = { isSearch, result: false, isMapExistingVideo };
      if (this.affiliationCourse === 0) {
        this.courseValidation = true;
        childObj.result = true;
        this.$emit("searchValidations", JSON.stringify(childObj));
        return;
      }
      if (this.affiliationSubject === 0) {
        this.subjectValidation = true;
        childObj.result = true;

        this.$emit("searchValidations", JSON.stringify(childObj));
        return;
      }
      if (this.affiliationTopic === 0) {
        this.topicvalidation = true;
        childObj.result = true;

        this.$emit("searchValidations", JSON.stringify(childObj));
        return;
      }
      if (this.affiliationChapter === 0) {
        this.chapterValidation = true;
        childObj.result = true;

        this.$emit("searchValidations", JSON.stringify(childObj));
        return;
      }
      if (this.affiliationBatch === null && this.showBatchDropDown) {
        this.batchValidation = true;
        childObj.result = true;

        this.$emit("searchValidations", JSON.stringify(childObj));
        return;
      }
      childObj.result = false;

      this.$emit("searchValidations", JSON.stringify(childObj));
    },
    closeAddModal() {
      this.$emit("modalClosed");
    },
    closeExistingModal() {
      this.$emit("existingModalClosed");
    },
    onUploadFile(e) {
      this.file = e.target.files || e.dataTransfer.files;
    },
    resetValues() {
      this.affiliationCourse = 0;
      this.affiliationSubject = 0;
      this.affiliationTopic = 0;
      this.affiliationChapter = 0;
      this.affiliationBatch = null;
      this.affiliationTitle = null;
      this.getInstituteVideos = [];
    },
    addBatch(newTag) {
      const tag = {
        Name: newTag,
        Id: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.batchData.push(tag);
      this.affiliationBatch.push(tag);
    },
    updateSelectedBatch(value) {
      if (this.batchData.length >= 0) {
        this.selectedBatches = [];
      }
      value.forEach((resource) => {
        this.selectedBatches.push(resource);
      });
      const batch = this.selectedBatches.map((a) => a.Id);
      this.batchId = batch.join(",");
      this.batchValidation = false;
      this.selectedProps.batches = this.selectedBatches;
      this.$emit("propsChanged", JSON.stringify(this.selectedProps));
    },
    selectBatchNone() {
      this.affiliationBatch = [];
      this.selectedProps.batches = [];
      this.batchValidation = true;
      this.$emit("propsChanged", JSON.stringify(this.selectedProps));
    },
    selectBatchAll() {
      this.affiliationBatch = this.batchData;
      this.selectedBatches = this.batchData;
      const batch = this.selectedBatches.map((a) => a.Id);
      this.batchId = batch.join(",");
      this.selectedProps.batches = this.selectedBatches;
      this.batchValidation = false;
      this.$emit("propsChanged", JSON.stringify(this.selectedProps));
    },
    onCourseChange(event) {
      this.courseId = Number(event.target.value);
      this.courseValidation = false;
      this.$store.dispatch("showLoader", true);
      const data = {
        onlyCourses: 0,
        courseId: this.courseId,
      };
      MobileAPI.getCourseMetaData(this.$store.getters.user.AffiliationId, data, (response) => {
        this.$store.dispatch("showLoader", false);
        this.subjectData = response.data[0];
        this.batchData = response.data[1];
      });
      this.affiliationSubject = 0;
      this.affiliationTopic = 0;
      this.affiliationChapter = 0;
      this.selectedProps.courseId = this.courseId;
      this.selectedProps.subjectId = this.affiliationSubject;
      this.selectedProps.topicId = this.affiliationTopic;
      this.selectedProps.chapterId = this.affiliationChapter;
      this.selectedProps.courseChapterId = 0;
      this.affiliationBatch = null;
      this.selectedBatches = [];
      this.$emit("propsChanged", JSON.stringify(this.selectedProps));
    },
    onSubjectChange(event) {
      this.subjectId = Number(event.target.value);
      this.subjectValidation = false;
      const selectedSubject = this.subjectData.find((a) => a.SubjectId === this.subjectId);
      this.topic = selectedSubject?.topicsList;
      this.affiliationTopic = 0;
      this.affiliationChapter = 0;
      this.selectedProps.topicId = this.affiliationTopic;
      this.selectedProps.chapterId = this.affiliationChapter;
      this.selectedProps.subjectId = this.subjectId;
      this.selectedProps.courseChapterId = 0;
      this.$emit("propsChanged", JSON.stringify(this.selectedProps));
    },
    onTopicChange(event) {
      this.topicId = Number(event.target.value);
      this.topicvalidation = false;
      this.topicData = this.topic.find((a) => a.TopicId === this.topicId);
      this.chapter = this.topicData.chapterList;
      this.selectedProps.topicId = this.topicId;
      this.affiliationChapter = 0;
      this.selectedProps.chapterId = this.affiliationChapter;
      this.selectedProps.courseChapterId = 0;
      this.$emit("propsChanged", JSON.stringify(this.selectedProps));
    },
    onChapterChange(event) {
      this.chapterId = Number(event.target.value);
      this.chapterValidation = false;
      this.selectedProps.chapterId = this.chapterId;
      this.selectedProps.courseChapterId = this.chapter.filter(
        (a) => a.ChapterId === Number(this.chapterId),
      )[0].CourseChapterId;
      this.$emit("propsChanged", JSON.stringify(this.selectedProps));
    },
    onBatchChange(event) {
      this.batchId = Number(event.target.value);
      this.batchValidation = false;
      this.selectedProps.batches = this.selectedBatches;
      this.$emit("propsChanged", JSON.stringify(this.selectedProps));
    },
    UploadStudyMaterial() {
      if (this.affiliationTitle === null) {
        this.titleValidation = true;
      }
      const fileField1 = document.getElementById("file1");
      if (fileField1.files.length === 0 || fileField1.files[0].type !== "application/pdf") {
        this.fileValidation = true;
        return;
      }
      const user = this.$store.getters.user;
      const formData = new FormData();
      formData.append("file", fileField1.files[0]);
      formData.append("AffiliationId", Number(user.AffiliationId));
      if (this.batchId.length > 1) {
        formData.append("CenterIds", this.batchId);
      } else {
        formData.append("CenterIds", Number(this.batchId));
      }
      formData.append("SubjectId", Number(this.subjectId));
      formData.append("TopicId", Number(this.topicId));
      formData.append("ChapterId", Number(this.affiliationChapter));
      formData.append("FileTypeId", Number(this.fileTypeId));
      formData.append("FileName", this.affiliationTitle);
      formData.append("CourseChapterId", Number(this.selectedProps.courseChapterId));
      this.$store.dispatch("showLoader", true);
      MobileAPI.addStudyMaterial(formData, (response) => {
        this.$store.dispatch("showLoader", false);
        this.addStudyMaterial = response.data.FileUploadId;
        if (response.responseCode === 500) {
          toast.info("Something went wrong. Please try again later.", {
            timeout: 2500,
          });
          this.$emit("videoUploadEvent", true);
        } else if (response.responseCode === 200) {
          toast.success("Added Successfully", {
            timeout: 2500,
          });
          this.$emit("videoUploadEvent", false);

          this.resetValues();
        }
        this.showAddModal = false;
      });
      // this.DetailsReset();
    },
    uploadData() {
      if (this.isFileUpload) {
        this.UploadStudyMaterial();
      } else {
        this.uploadCoachingVideo();
      }
    },
    uploadCoachingVideo() {
      const fileField1 = document.getElementById("file1");
      if (this.affiliationTitle === null) {
        this.titleValidation = true;
        return;
      }
      if (fileField1.files.length === 0 || fileField1.files[0].type !== "video/mp4") {
        this.fileValidation = true;
        return;
      }
      const formData = new FormData();
      formData.append("file", fileField1.files[0]);
      formData.append("Title", this.affiliationTitle);
      formData.append("CourseId", Number(this.courseId));
      formData.append("SubjectId", Number(this.subjectId));
      formData.append("TopicId", Number(this.topicId));
      formData.append("ChapterId", Number(this.chapterId));
      formData.append("ClassId", Number(this.classId));
      formData.append("CourseChapterId", Number(this.selectedProps.courseChapterId));
      this.$store.dispatch("showLoader", true);
      MobileAPI.addCoachingVideo(formData, (response) => {
        this.$store.dispatch("showLoader", false);
        this.videoId = response.data.CoachingVideoId;
        if (response.responseCode === 500) {
          this.showAddModal = false;
          toast.info("Something went wrong. Please try again later.", {
            timeout: 2500,
          });
          this.$emit("videoUploadEvent", true);
        } else if (response.responseCode === 200) {
          this.showAddModal = false;
          toast.success("Added Successfully", {
            timeout: 2500,
          });
          // this.resetValues();
          this.$emit("videoUploadEvent", false);
          this.affiliationTitle = null;
        }
      });
    },
    mapExistingVideo() {
      if (this.affiliationTitle === null) {
        this.titleValidation = true;
        return;
      }
      if (this.videoUrl === null) {
        this.videoUrlValidation = true;
        return;
      }
      const data = {
        CourseId: this.courseId,
        SubjectId: this.subjectId,
        TopicId: this.topicId,
        ChapterId: this.chapterId,
        VideoTitle: this.affiliationTitle,
        VideoURL: this.videoUrl,
        IsYoutube: this.isYouTube,
        CourseChapterId: this.selectedProps.courseChapterId,
      };

      MobileAPI.mapExistingInstituteVideo(data, (response) => {
        this.$store.dispatch("showLoader", false);
        const result = response.data;
        if (response.responseCode === 500) {
          this.showAddModal = false;
          toast.info("Something went wrong. Please try again later.", {
            timeout: 2500,
          });
        } else if (response.responseCode === 200 && result > 0) {
          this.showMapExistingModal = false;
          toast.success("Mapped Successfully", {
            timeout: 2500,
          });
          // this.resetValues();
          this.closeExistingModal();
          this.affiliationTitle = null;
          this.videoUrl = null;
          this.$emit("videoMappedEvent", true);
        }
      });
    },
  },
};
</script>

<style scoped>
.icon-bullet {
  width: 24px;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");
@import "https://unpkg.com/vue-multiselect@2.0.2/dist/vue-multiselect.min.css";
@import url("https://cdn.jsdelivr.net/npm/vue3-treeselect@^0.1.1/dist/vue3-treeselect.min.css");

* {
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box !important;
}

/* custom css */
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.dark-text {
  color: #333333;
}

.line-margin {
  margin: 7px 0;
}

.gray-text-clr {
  color: #333333;
}

/* main css */
.main-container {
  margin-top: 20px;
  padding: 0 10px;
}

.test-list {
  box-shadow: 4px 4px 10px rgb(100 44 144 / 6%);
  border-radius: 6px;
  padding: 12px 17px;
}

.add-btn {
  font-weight: 600;
  font-size: 14px;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 7px 18px;
  cursor: pointer;
}

input[type="text"] {
  border: 1px solid #e0e4f0 !important;
  background-color: white !important;
  height: 43px;
  border-radius: 4px;
  padding: 2px 10px;
  margin: 0;
  /* border-bottom: none !important; */
}

input[type="text"]:not(.browser-default):focus:not([readonly]) {
  /* border-bottom: none !important; */
  box-shadow: none;
}

input[type="text"]:not(.browser-default) {
  height: 43px !important;
  /* border-bottom: none !important; */
}

select {
  border: 1px solid #e0e4f0 !important;
  background-color: white !important;
  height: 43px;
  border-radius: 4px;
  font-size: 14px;
  color: #adadad;
}

select:focus {
  outline: none;
}

#select-input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: url("../assets/Vector4.svg") no-repeat right;
  background-size: 38px 6px;
  height: 43px;
  padding-left: 7px;
}

select option:checked {
  color: #3751ff;
  font-weight: 600;
}

option:disabled {
  color: rgb(170, 170, 170) !important;
  font-weight: 500 !important;
}

select {
  overflow: hidden;
}

select::-webkit-scrollbar {
  width: 0;
}

select option {
  color: #333333 !important;
}

input::-webkit-input-placeholder {
  color: #adadad;
}

input::-moz-placeholder {
  color: #adadad;
}

input::-ms-input-placeholder {
  color: #adadad;
}

input::placeholder {
  color: #adadad;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 27px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  /* -webkit-font-feature-settings: 'liga'; */
  -webkit-font-smoothing: antialiased;
  /* cursor: pointer; */
}

.tooltiptext {
  visibility: hidden;
  background-color: #3fbbb9;
  color: white;
  text-align: center;
  padding: 2px 8px;
  border-radius: 3px;
  z-index: 1;
  white-space: break-spaces;
  text-align: center;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  transition: 0.3s all ease;
  font-weight: 600;
  position: absolute;
  left: -15px;
  bottom: -32px;
  overflow: visible;
}

.tooltiptext::before {
  position: absolute;
  width: 10px;
  height: 10px;
  content: "";
  background: #3fbbb9;
  left: 42%;
  top: -5px;
  -webkit-backdrop-filter: #3fbbb9;
  backdrop-filter: #3fbbb9;
  transform: rotate(45deg);
  background: #3fbbb9;
  visibility: hidden;
  transition: 0.1s all ease;
  line-height: 20px;
}

.tooltip:hover .tooltiptext,
.tooltip:hover .tooltiptext::before {
  visibility: visible;
}

.pagination {
  padding: 10px 14px;
  align-items: center;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.warning-section-modal {
  width: 400px;
  /* height: 356px; */
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirm-btn {
  width: 84px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #37841c;
  border-radius: 4px;
  background: #ffffff;
  color: #37841c;
  cursor: pointer;
  margin-right: 10px;
}

.confirm-btn:hover {
  background: #37841c !important;
  color: #ffffff !important;
}

.cancel-btn {
  width: 84px;
  padding-right: 5px;
  padding-left: 5px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #ff7675;
  border-radius: 4px;
  color: #ff7675;
  cursor: pointer;
  margin-left: 10px;
}

.instruction-line {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-size: 9px;
  line-height: 12px;
  color: #333333;
  /* margin-bottom: 6px; */
}

.cancel-btn:hover {
  background: #ff7675 !important;
  color: #ffffff !important;
}

.concept-modal {
  max-width: 1173px !important;
  /* min-width: 650px !important; */
  width: 100% !important;
}

.publish-head {
  position: relative;
  width: 100%;
  background: #f3f5fc;
  padding: 10px;
  border-radius: 5px 5px 0 0;
}

.pl-5 {
  padding-left: 5px;
}

.p5 {
  padding: 5px;
}

.publish-close {
  background-color: #ff7675 !important;
  border: 1px solid #e0e4f0;
  border-radius: 3px;
}

.text-div {
  display: flex;
  align-items: center;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.modal-content {
  padding: 10px;
}

.mb-0 {
  margin-bottom: 0;
}

.date-column {
  height: 39px !important;
  color: black !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-left: 12px !important;
  background: #ffffff;
  border: 1px solid #e0e4f0 !important;
  border-radius: 4px !important;
  /* width: 310px !important; */
}

.light-gray-text {
  color: #333333;
}

.publish-modal-button {
  background: #37841c;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  margin-right: 30px;
  cursor: pointer;
  padding: 10px 15px;
}

.dark-font {
  color: #333;
}

.error-message {
  position: absolute;
  color: red;
  font-size: 14px;
}

/* Media Query */
@media screen and (max-width: 992px) {
  .input-box {
    margin-bottom: 10px;
  }

  .test-list {
    display: block;
  }

  .column-3 {
    clear: left;
  }

  .column-3 h2:nth-child(1) {
    margin-top: 7px;
  }

  .pagination {
    margin-bottom: 10px;
  }

  .test-details {
    display: block !important;
  }
}

@media screen and (max-width: 600px) {
  /* .row{
      margin-bottom: 0;
    } */
  .add-btn {
    margin-bottom: 20px;
  }

  .input-box {
    padding-right: 0 !important;
  }

  .warning-section-modal {
    width: 300px;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
